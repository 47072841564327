@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Rajdhani', 'Roboto', 'Segoe UI', system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-grid-pattern {
  background-size: 24px 24px;
  background-image:
          linear-gradient(to right, rgba(31, 41, 55, 0.1) 1px, transparent 1px),
          linear-gradient(to bottom, rgba(31, 41, 55, 0.1) 1px, transparent 1px);
}

@layer utilities {
  .text-gradient {
    @apply bg-clip-text text-transparent bg-primary-gradient;
  }

  .border-gradient {
    border-image: linear-gradient(to right, #a855f7, #6366f1) 1;
  }

  .btn-gradient {
    @apply bg-primary-gradient hover:bg-primary-gradient-hover transition-all duration-300;
  }
}